import "./style.css";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import twitter_icon from "../../assets/images/icons/fa-twitter_white.png";
import instagram_icon from "../../assets/images/icons/fa-instagram-white.png";
import linkedin_icon from "../../assets/images/icons/fa-linkedin-white.png";

export default function Footer() {
    const location = useLocation();
    let navigate = useNavigate()
    return (
        <footer className={location.pathname !=="/" ? "footer_cont_grey font_DMSans":"footer_cont font_DMSans"}>
            <div className="footer_b1 d_flex align_items_center justify_content_space_between">
                <p className="fs_40 f_400">Easiest ERP Interface Ever</p>
                <div className="d_flex align_items_center justify_content_space_between header_navlinks_cont">
                    <div className="d-flex align-items-center justify-content-space-between">
                        <NavLink
                            className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_14 f_600' : 'footer_navLink fs_14 f_400'}
                            to="/policy">Privacy Policy</NavLink>
                        <NavLink
                            className={({isActive}) => isActive ? 'footer_navLink_active c_white fs_14 f_600' : 'footer_navLink fs_14 f_400'}
                            to="/terms">Terms</NavLink>
                    </div>
                    <div className="d_flex align_items_center social_block">
                        <a href="" target="_blank"><img src={twitter_icon} alt="twitter_icon"/></a>
                        <a href="" target="_blank"><img src={instagram_icon} alt="instagram_icon"/></a>
                        <a href="" target="_blank"><img src={instagram_icon} alt="instagram_icon"/></a>
                    </div>
                </div>
            </div>
            <div className="footer_b2 d_flex align_items_center justify_content_space_between">
                <div className="d-flex align-items-center justify-content-space-between">
                    <NavLink
                        className={({isActive}) => isActive ? 'footer_navLink_active  c_white fs_14 f_600' : 'footer_navLink fs_14 f_400'}
                        to="/">Home</NavLink>
                    <NavLink
                        className={({isActive}) => isActive ? 'footer_navLink_active c_white fs_14 f_600' : 'footer_navLink fs_14 f_400'}
                        to="/pricing">Pricing Plans</NavLink>
                    <NavLink
                        className={({isActive}) => isActive ? 'footer_navLink_active c_white fs_14 f_600' : 'footer_navLink fs_14 f_400'}
                        to="/about">About us</NavLink>
                    <NavLink
                        className={({isActive}) => isActive ? 'footer_navLink_active c_white fs_14 f_600' : 'footer_navLink fs_14 f_400'}
                        to="/contact">Contact Sales</NavLink>
                </div>
                <p className="fs_16 f_400 all_rights">© 2023 Cogibot. All rights reserved.</p>
            </div>
        </footer>
    )
}