import Modal from 'react-modal';
import "./style.css";
import {useState} from "react";
import close_icon_grey from "../../../../assets/images/icons/close_icon_grey.png";
import {useNavigate} from "react-router-dom";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "524px",
        width: "100%"
    },
};

export default function ResetPasswordModal(props) {
    let navigate = useNavigate()

    let _continueResetPass = () => {
        props.setResetPasswordContinueModal(true)
        props.setResetPassModalIsOpen(false)
    }

    return (
        <div className="">
            <Modal
                isOpen={props.resetPassModalIsOpen}
                onRequestClose={props.closeResetPassModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="">
                    <div className=" d_flex fd_column modal_content">
                        <img src={close_icon_grey} alt="close_icon" className="close_icon"
                             onClick={props.closeResetPassModal}/>
                        <h2 className="fs_24  f_600  margin_top_16">Reset password</h2>
                        <div className="fs_14 f_600 c_grey info_email_text">
                            Please, enter email connected with your account
                            we will send you email with a recovery link.
                        </div>
                        <div className="margin_top_24 d_flex fd_column justify_content_start contact_sales_form_cont_item">
                            <label htmlFor="" className="fs_14 c_grey f_400">Email</label>
                            <input type="email" placeholder="Email" className="fs_14"/>
                        </div>
                        <div className="border_wrap_send_btn margin_top_24" onClick={_continueResetPass}>
                            <div className="border_wrap_send_btn_inner">
                                <div className="d_flex justify_content_center  send_btn_b bc_colorful">
                                    <button className="send_btn fs_14 f_600">Continue</button>
                                </div>
                            </div>
                        </div>
                        <p className="contact_sales c_grey fs_14 text_left margin_top_24">Don’t have an access to your
                            email? <a
                                onClick={() => navigate("/contact")} className="">Contact us</a>
                        </p>

                    </div>
                </div>

            </Modal>

        </div>
    );
}