import Modal from 'react-modal';
import "./style.css";
import {useState} from "react";
import close_icon_grey from "../../../../../assets/images/icons/close_icon_grey.png";
import {useNavigate} from "react-router-dom";
import ok_icon from "../../../../../assets/images/icons/ok_icon_grey.png";
import left_arrow from "../../../../../assets/images/icons/ic_Settings.png";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "524px",
        width: "100%"
    },
};

export default function UpgradeModal(props) {
    let navigate = useNavigate()
    let [btnActive, setBtnActive] = useState(false)
    let [billingPlanActive, setBillingPlanActive] = useState(false)
    let [usersNumbers, setUsersNumbers] = useState([
        {
            number: 5,
            id: 1,
        },
        {
            number: 10,
            id: 2,
        },
        {
            number: 50,
            id: 3,
        },
        {
            number: "More than 50",
            id: 5,
        },
    ])
    let [billingPlan, setBillingPlan] = useState([
        {
            id: 1,
            method: "Free Trial",
            price: 0,
            users: "per user",
        },
        {
            id: 2,
            method: " 6 months",
            price: 500,
            users: "per user per 6 months",
        },
        {
            id: 3,
            method: "Annualy",
            price: 450,
            users: "per user per year",
        },
        {
            id: 4,
            method: "2 years",
            price: 400,
            users: "per user per year",
        },
    ])

    // let _continueResetPass = () => {
    //     props.setResetPasswordContinueModal(true)
    //     props.setResetPassModalIsOpen(false)
    // }

    return (
        <div className="">
            <Modal
                isOpen={props.upgradeModalIsOpen}
                onRequestClose={props.closeUpgradeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="">
                    <div className=" d_flex fd_column modal_content_" style={{background: "transparent"}}>
                        <img src={close_icon_grey} alt="close_icon" className="close_icon"
                             onClick={props.closeUpgradeModal}/>
                        <div className="d_flex subscribe_enterprise_blocks">
                            <div
                                className="d_flex fd_column justify_content_start align_items_start subscribe_enterprise_b1">
                                <p>Plan Enterprise Plus</p>
                                <div className="included_plan_div">
                                    <p className="fs_12 f_600">Included in plan:</p>
                                    <div className="d_flex align_items_center c_grey">
                                        <img src={ok_icon} alt=""/>
                                        Feature 1
                                    </div>
                                    <div className="d_flex align_items_center c_grey">
                                        <img src={ok_icon} alt=""/>
                                        Feature 2
                                    </div>
                                    <div className="d_flex align_items_center c_grey">
                                        <img src={ok_icon} alt=""/>
                                        Feature 3
                                    </div>
                                    <div className="d_flex align_items_center c_grey">
                                        <img src={ok_icon} alt=""/>
                                        Feature 4
                                    </div>
                                </div>
                                <div className="d_flex align_items_center cancel_save_btn margin_top_24">
                                    <div className="button_border">
                                        <div className="button_border_back">
                                            <div className="d_flex justify_content_start cancel_btn_b">
                                                <button className=" fs_14 f_600 cancel_btn">Contact Enterprise Sales
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d_flex align_items_center margin_top_24">
                                    <p className="fs_12 c_grey f_600">Compare Plans</p>
                                    <img src={left_arrow} alt="arrow"/>
                                </div>
                            </div>
                            <div className="subscribe_enterprise_b2">
                                <h2 className="fs_24  f_600 margin_top_24">Subscribe to Enterprise Plus</h2>
                                <div className="d_flex fd_column justify_content_start margin_top_24">
                                    <p className="f_400 fs_14 c_lightGrey">Number of users</p>
                                    <div className="number_users_btn_b d_flex align_items_center">
                                        {
                                            usersNumbers.map((item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={btnActive === index ? "button_border" : "right_16"}>
                                                            <button
                                                                className="number_users_btn fs_14 f_600"
                                                                onClick={() => setBtnActive(index)}>{item.number}</button>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="d_flex fd_column justify_content_start margin_top_24">
                                    <p className="f_400 fs_14 c_lightGrey">Billing Plan</p>
                                    <div className="d_grid grid_columns_2fr  billing_plan_btns_b">
                                        {
                                            billingPlan.map((item, index) => {
                                                return (
                                                    <>
                                                        <div
                                                            className={billingPlanActive === index ? "button_border" : "right_16"}>
                                                            <div
                                                                className="billing_plan_btn d_flex fd_column justify_content_start align_items_start"
                                                                onClick={() => setBillingPlanActive(index)}>
                                                                <p className="fs_14 f_600">{item.method}</p>
                                                                <div className="d_flex align_items_center">
                                                                    <div className="fs_16 f_600">${item.price}</div>
                                                                    <span
                                                                        className="fs_12 f_400 c_grey">{item.users}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div
                                    className="margin_top_24 d_flex fd_column justify_content_start contact_sales_form_cont_item">
                                    <label htmlFor="" className="fs_14 c_grey f_400">Payment option</label>
                                    <input type="email" placeholder="Card Number" className="fs_14"/>
                                </div>
                                <div
                                    className="d_flex align_items_center justify_content_space_between subscribe_cancel_b margin_top_24">
                                    <div className="button_border d_flex">
                                        <div className=" subscribe_now_btn">
                                            <div
                                                className="d_flex align_items_center justify_content_space_between subscribe_now_btn_inner">
                                                <div className="fs_12 f_600">
                                                    Subscribe now
                                                </div>
                                                <div className="fs_16 f_600 total_price">
                                                    Total <span>$450</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="cancel_save_btn" onClick={props.closeUpgradeModal}>
                                        <div className="button_border" style={{marginLeft: "16px"}}>
                                            <div className="button_border_back">
                                                <div className="d_flex justify_content_start cancel_btn_b">
                                                    <button className=" fs_14 f_600 cancel_btn">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="contact_sales c_grey fs_14 text_left margin_top_24">You’ll be charged $57
                                    now for one year of subscription. Change may apply renewal. By clicking on
                                    subscribe, you agree our Terms and Privacy Policy.
                                </p>
                            </div>
                        </div>


                    </div>
                </div>

            </Modal>

        </div>
    );
}