import Modal from 'react-modal';
import "./style.css";
import {useState} from "react";
import close_icon_grey from "../../../../assets/images/icons/close_icon_grey.png";
import {useLocation, useNavigate} from "react-router-dom";
import ok_icon_green from "../../../../assets/images/icons/ok_Icon_green.png";
import ok_icon_grey from "../../../../assets/images/icons/ok_icon_grey.png";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "524px",
        width: "100%"
    },
};

export default function ChangePasswordModal(props) {
    let navigate = useNavigate();
    let history=useLocation();
    let [show, setShow] = useState(false)
    const [reset, setReset] = useState({
        password: "",
        c_password: "",
        // code: history.state.code
    })
    const [error, setError] = useState("")
    const [resetBtn, setResetBtn] = useState(false)

    const handleChangeReset = (e, name) => {
        setReset({...reset, [name]: e})
        setError("")
    }

    const  _handleChangePassword = () => {
        let values={
            password: reset.password,
            c_password:reset.c_password,
            // code:reset.code
        }
        setResetBtn(true)
        if (reset.password.length && reset.c_password.length) {
            if(reset.password.length>10){
                if(reset.password === reset.c_password) {
                    console.log(values,"etetg")
                    props.setChangePasswordModalIsOpen(false)
                    navigate("/sign_in")
                }
                else{
                    setError("Passwords are different")
                }
            }else {
                setError("Password length is small")
            }
        }
    }

    return (
        <div className="">
            <Modal
                isOpen={props.changePasswordModalIsOpen}
                onRequestClose={props.closeChangePasswordModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="">
                    <div className=" d_flex fd_column modal_content">
                        <img src={close_icon_grey} alt="close_icon" className="close_icon"
                             onClick={props.closeChangePasswordModal}/>
                        <h2 className="fs_24  f_600  margin_top_16">Change password</h2>
                        <div className="margin_top_24 d_flex fd_column justify_content_start contact_sales_form_cont_item">
                            <label htmlFor="" className="fs_14 c_grey f_400">Current password</label>
                            <input type="password" placeholder="Current password" className="fs_14"
                                   style={!reset.password && resetBtn ? {border: "1px solid red"} : null}
                                   onChange={(e) => handleChangeReset(e.target.value, "password")}
                            />
                        </div>
                        <div className="margin_top_24 d_flex fd_column justify_content_start contact_sales_form_cont_item">
                            <label htmlFor="" className="fs_14 c_grey f_400">New password</label>
                            <input type="password" placeholder="New password" className="fs_14"
                                   style={!reset.c_password && resetBtn ? {border: "1px solid red"} : null}
                                   onChange={(e) => handleChangeReset(e.target.value, "c_password")}
                            />
                            {
                                reset.c_password ?
                                    <div className="d_flex fd_column justify_content_start password_desc_b">
                                        <p className="fs_14 f_400">Your password must have</p>
                                        <div className="font_Rubik fs_14 f_400 d_flex align_items_center c_green"><img src={ok_icon_green} alt=""/>Minimum 10 symbols</div>
                                        <div className="font_Rubik fs_14 f_400 d_flex align_items_center c_lightGrey"><img src={ok_icon_grey} alt=""/>At least 1 uppercase</div>
                                        <div className="font_Rubik fs_14 f_400 d_flex align_items_center c_lightGrey"><img src={ok_icon_grey} alt=""/>At least 1 lowercase</div>
                                        <div className="font_Rubik fs_14 f_400 d_flex align_items_center c_lightGrey"><img src={ok_icon_grey} alt=""/>At least 1 number</div>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                        <div className="border_wrap_send_btn margin_top_24" onClick={_handleChangePassword}>
                            <div className="border_wrap_send_btn_inner">
                                <div className="d_flex justify_content_center  send_btn_b bc_colorful">
                                    <button className="send_btn fs_14 f_600">Change password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>

        </div>
    );
}