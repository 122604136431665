import "./style.css";
import user_pic from "../../../../../assets/images/Admin_pic.png";

export default function UserInfo() {
    return (
        <div className="admin_profile_cont">
        <div className="user_info_cont  d_flex justify_content_space_between align_items_start">
            <div className="user_info_cont_b1">
                <p className="fs_20 f_600">User info</p>
                <div className="user_info_cont_b1_inputs_b  margin_top_24">
                    <div className="d_flex fd_column justify_content_start user_info_cont_b1_input_b">
                        <label htmlFor="" className="fs_14 f_400 c_lightGrey">Name</label>
                        <input type="text" placeholder="Name"/>
                    </div>
                    <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b">
                        <label htmlFor="" className="fs_14 f_400 c_lightGrey">Company Name</label>
                        <input type="text" placeholder="Codibot"/>
                    </div>
                    <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b">
                        <label htmlFor="" className="fs_14 f_400 c_lightGrey">Email</label>
                        <input type="email" placeholder="Email"/>
                    </div>
                    <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b">
                        <label htmlFor="" className="fs_14 f_400 c_lightGrey">Department / Job Description</label>
                        <input type="text" placeholder="Job Description"/>
                    </div>
                </div>
                <div className="d_flex align_items_center cancel_save_btn margin_top_32">
                    <div className="button_border">
                        <div className="button_border_back">
                            <div className="d_flex justify_content_start  save_btn_b">
                                <button className="bc_colorful fs_14 f_600 save_btn">Save</button>
                            </div>
                        </div>
                    </div>
                    <div className="button_border" style={{marginLeft:"16px"}}>
                        <div className="button_border_back">
                            <div className="d_flex justify_content_start cancel_btn_b">
                                <button className=" fs_14 f_600 cancel_btn">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="user_info_cont_b2 d_flex fd_column justify_content_center">
                <img src={user_pic} alt="admin_pic" className="admin_pic"/>
                <p className="margin_top_16 fs_14 f_600 text_center">User photo</p>
                <div className="upload_delete_pic_btns_b d_flex">
                    <button className="fs_14 f_400 font_Roboto">Upload</button>
                    /
                    <button className="fs_14 f_400 font_Roboto">Delete</button>
                </div>
            </div>
        </div>
        </div>
    )
}