import "./style.css";
import logo from "../../assets/images/logo.png";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import send_icon from "../../assets/images/icons/send_icon_black.png";
import {useState} from "react";
import search_icon from "../../assets/images/icons/Search_icon.png";
import notification_icon from "../../assets/images/icons/ic_Notification.png";
import Notification_sign from "../../assets/images/icons/Notification_sign.png";
import profile_pic from "../../assets/images/Profile_pic.png";
import arrow_down from "../../assets/images/icons/Dropdown_down_grey.png";


export default function Header() {
    let location = useLocation()
    let navigate = useNavigate()
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");

    const LogoutUser = () => {
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("jwtToken")
        localStorage.removeItem("token")
        navigate("/sign_in")
        console.log(token);
        // axios.get(`${config.url}/auth/logout`, {
        //     headers: {
        //         'authorization': `${token}`
        //     }
        // })
        //     .then(response => {
        //         console.log(response, "logout");
        //         localStorage.removeItem("refreshToken")
        //         localStorage.removeItem("jwtToken")
        //         localStorage.removeItem("token")
        //         navigate("/login")
        //     })
        //     .catch(error => {
        //         console.log(error.response, "elq")
        //     })
    }
    return (
        <>
            {
                !token ?
                    <header className="header_cont d_flex justify_content_space_between align_items_center bc_black">
                        <div onClick={() => navigate("/")}>
                            <img src={logo} alt="logo" className="logo"/>
                        </div>
                        <div className="d_flex align_items_center justify_content_space_between header_navlinks_cont">
                            <div className="d-flex align-items-center justify-content-space-between">
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active  c_white fs_14 f_700' : 'header_navLink fs_14 f_600'}
                                    to="/product">Product</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active c_white fs_14 f_700' : 'header_navLink fs_14 f_600'}
                                    to="/privacy">Data Privacy</NavLink>
                                <NavLink
                                    className={({isActive}) => isActive ? 'header_navLink_active c_white fs_14 f_700' : 'header_navLink fs_14 f_600'}
                                    to="/about">About us</NavLink>
                            </div>
                            {
                                location.pathname === "/policy" ?
                                    <div className="login_register_btn_block">
                                        <button
                                            className="fs_16 c_black contact_us_btn login_btn f_600 bc_white d_flex align_items_center"
                                            onClick={() => navigate('/sign_in')}><img src={send_icon} alt="send_icon"
                                                                                      className="send_icon"/> Sign In
                                        </button>
                                    </div>
                                    :
                                    <div className="button_border">
                                        <div className="button_border_back">
                                            <div className="login_register_btn_block bc_colorful ">
                                                <button className="fs_16 c_white contact_us_btn f_600 login_btn"
                                                        onClick={() => navigate('/sign_in')}>Sign In
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </header>
                    :
                    <header  className="header_cont d_flex justify_content_space_between align_items_center bc_black">
                        <div style={{marginLeft:"15%"}}>
                            <p className="fs_14 f_400">Model: Web Browsing</p>
                        </div>
                        <div className="d_flex align_items_center justify_content_space_between header_navlinks_cont">
                            <div className="search_b">
                                <img src={search_icon} alt="pic"/>
                            </div>
                            <div className="d_flex align_items_center notification_b">
                                <div>
                                    <img src={Notification_sign} alt="pic"/>
                                </div>
                                {/*<div>*/}
                                {/*    <img src={notification_icon} alt=""/>*/}
                                {/*</div>*/}
                            </div>
                            <div className="d_flex align_items_center profile_b" onClick={LogoutUser}>
                                <div className="d_flex align_items_center  profile_b1">
                                    <img src={profile_pic} alt="pic" className="profile_pic"/>
                                    <div className="d_flex fd_column justify_content_start profile_name_b">
                                        <span className="fs_14 f_600">Austin Robertson</span>
                                        <span className="c_lightGrey fs_12">Marketing Administrator</span>
                                    </div>
                                </div>
                                <img src={arrow_down} alt="arrow_down"/>
                            </div>
                        </div>
                    </header>
            }
        </>
    )
}