import "./style.css";
import {useNavigate} from "react-router-dom";
import Header from "../../components/header/header";
import privacy_b2_pic
    from "../../assets/images/Tamera_A_digital_fortress_protecting_the_inner_workings_of_data_cf6d8b13-9bd0-43f7-8d4d-6408fc02e8d9 (1) 1.svg";
import privacy_b3_pic from "../../assets/images/privacy_b3_pic.svg";
import privacy_b4_pic from "../../assets/images/privacy_b4_pic.svg";
import arrow_right_white from "../../assets/images/icons/arrow_right_white.svg";
import Footer from "../../components/footer/footer";

export default function DataPrivacy() {
    let navigate = useNavigate()
    return (
        <div className="main">
            <div className="">
                <Header/>
                <div className="privacy_b1">
                    <div
                        className="home_b1_content privacy_b1_content d_flex fd_column align_items_center justify_content_center">
                        <h1 className="fd_96 text_center f_400 font_WorkSans">Data Privacy</h1>
                        <div className="fs_24 f_400 font_WorkSans text_center margin_top_32">Your Privacy is our
                            priority. Find out our principles about data operations.
                        </div>
                        <div className="button_border margin_top_64" style={{maxWidth: "279px", width: "100%"}}>
                            <div className="button_border_back">
                                <div className="contact_us_btn_b bc_colorful"
                                     onClick={() => navigate("/contact")}>
                                    <button className="contact_us_btn fs_20 c_white">Contact us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container_big_sizes">
                    <div className="privacy_cont">
                        <div className="privacy_b2 d_flex justify_content_space_between align_items_center margin_top_140">
                            <div className="privacy_b2_b1">
                                <img src={privacy_b2_pic} alt="pic"/>
                            </div>
                            <div className="privacy_b2_b2 d_flex fd_column">
                                <p className="fs_42 f_500 font_WorkSans">
                                    Secured Connectivity, Anywhere, Anytime:</p>
                                <p className="fs_24 f_400 font_Inter margin_top_16 font_Inter">Our SaaS Solution for ERP
                                    Data Privacy</p>
                                <div className="info_b fs_24 f_400 margin_top_16 font_Inter">
                                    Leverage our software's encrypted mobile and web interface for secure ERP data
                                    integration. With zero data storage and customizable access roles, you're in control
                                    of your data's privacy
                                </div>
                                <div className="button_border margin_top_32">
                                    <div className="button_border_back">
                                        <div className="contact_us_btn_b_small bc_colorful"
                                             onClick={() => navigate("/contact")}>
                                            <button className="contact_us_btn_small fs_20 c_white">Contact Sales</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="privacy_b3 d_flex align_items_center justify_content_space_between margin_top_140">
                            <div className="privacy_b3_b1 d_flex fd_column">
                                <p className="fs_42 f_500 font_WorkSans">Take Control of Your ERP Data Security</p>
                                <p className="fs_24 f_400 font_Inter margin_top_16">Custom Access Roles, Zero Data
                                    Storage</p>
                                <div className="info_b fs_24 f_400 margin_top_16 font_Inter">
                                    Our SaaS solution offers robust security for your ERP data across all platforms. We
                                    guarantee zero data storage and empower you with custom access roles for added
                                    control.
                                </div>
                                <div className="button_border margin_top_32">
                                    <div className="button_border_back">
                                        <div className="contact_us_btn_b_small bc_colorful"
                                             onClick={() => navigate("/contact")}>
                                            <button className="contact_us_btn_small fs_20 c_white">Contact Sales</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="privacy_b3_b2">
                                <img src={privacy_b3_pic} alt="pic"/>
                            </div>
                        </div>
                        <div className="privacy_b4 d_flex justify_content_space_between align_items_center margin_top_140">
                            <div className="privacy_b4_b1">
                                <img src={privacy_b4_pic} alt="pic"/>
                            </div>
                            <div className="privacy_b4_b2 d_flex fd_column">
                                <p className="fs_42 f_500 font_WorkSans">
                                    Secured Connectivity, Anywhere, Anytime:</p>
                                <p className="fs_24 f_400 font_Inter margin_top_16 font_Inter">Our SaaS Solution for ERP
                                    Data Privacy</p>
                                <div className="info_b fs_24 f_400 margin_top_16 font_Inter">
                                    Leverage our software's encrypted mobile and web interface for secure ERP data
                                    integration. With zero data storage and customizable access roles, you're in control
                                    of your data's privacy
                                </div>
                                <div className="button_border margin_top_32">
                                    <div className="button_border_back">
                                        <div className="contact_us_btn_b_small bc_colorful"
                                             onClick={() => navigate("/contact")}>
                                            <button className="contact_us_btn_small fs_20 c_white">Contact Sales</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="button_border margin_top_140 margin_bottom_150 contact_sales_big_btn" >
                            <div className="button_border_back" style={{borderRadius:"24px"}}>
                                <div className="contact_sales_b  ">
                                    <div
                                        className="contact_sales_btn_b d_flex justify_content_space_between align_items_center"
                                        onClick={() => navigate("/contact")} style={{margin:"unset"}}>
                                        <p className="font_WorkSans fs_80">Contact Sales</p>
                                        <img src={arrow_right_white} alt="arrow_pic" className="arrow_pic_white"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}