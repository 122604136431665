import "./style.css";
import Sidebar from "../../../components/sidebar/Sidebar";
import Header from "../../../components/header/header";
import {useState} from "react";
import UserInfo from "./components/user_info/UserInfo";
import CompanyProfile from "./components/company_profile/CompanyProfile";
import Subscription from "./components/subscription/Subscription";

export default function AdminProfile() {
    let [toggleTabState, setToggleTabState] = useState('Admin_profile')


    let toggleTabs = (item) => {
        setToggleTabState(item)
    }
    return (
        <div className="webapp_user_back">
            <Sidebar/>
            <Header isLogin={true}/>
            <div className="admin_tabs">
                <div className="button_tabs">
                    <div className={`btn_main  ${toggleTabState === "Admin_profile" && 'btn_main_active'}`}
                         onClick={() => toggleTabs("Admin_profile")}>
                        Admin profile
                    </div>
                    <div className={`btn_main ${toggleTabState === "Company_profile" && 'btn_main_active'}`}
                         onClick={() => toggleTabs("Company_profile")}>
                        Company profile
                    </div>
                    <div className={`btn_main ${toggleTabState === "Subscriptions" && 'btn_main_active'}`}
                         onClick={() => toggleTabs("Subscriptions")}>
                        Subscriptions
                    </div>
                    <div className={`btn_main ${toggleTabState === "Roles" && 'btn_main_active'}`}
                         onClick={() => toggleTabs("Roles")}>
                        Roles
                    </div>
                    <div className={`btn_main ${toggleTabState === "Users" && 'btn_main_active'}`}
                         onClick={() => toggleTabs("Users")}>
                        Users
                    </div>
                    <div className={`btn_main ${toggleTabState === "Users_logs" && 'btn_main_active'}`}
                         onClick={() => toggleTabs("Users_logs")}>
                        Users logs
                    </div>
                    <div className={`btn_main ${toggleTabState === "Report_issue" && 'btn_main_active'}`}
                         onClick={() => toggleTabs("Report_issue")}>
                        Report issue
                    </div>
                </div>
            </div>
            <div className="Admin_cont">
                <div className="">
                    {toggleTabState === "Admin_profile" ?
                        <UserInfo/>
                        : null
                    }
                    {toggleTabState === "Company_profile" ?
                        <CompanyProfile/>
                        : null
                    }
                    {toggleTabState === "Subscriptions" ?
                        <Subscription/>
                        : null
                    }
                    {toggleTabState === "Roles" ?
                        <p>Roles</p>
                        : null
                    }
                    {toggleTabState === "Users" ?
                        <p>Users</p>
                        : null
                    }
                    {toggleTabState === "Users_logs" ?
                        <p>Users logs</p>
                        : null
                    }
                    {toggleTabState === "Report_issue" ?
                        <p>Report_issue</p>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}