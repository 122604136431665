import "./style.css";
import {useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import logo from "../../assets/images/logo.png";
import webChat_active from "../../assets/images/icons/webchat_active.png";
import webChat_not_active from "../../assets/images/icons/webchat_not_active.png";
import write_message from "../../assets/images/icons/ic_Write_message.png";
import arrow_up from "../../assets/images/icons/ic_Chevron_Up.png";
import arrow_down from "../../assets/images/icons/ic_Chevron_Down.png";
import voice_chat_icon from "../../assets/images/icons/voice_chat_icon.png";
import voice_chat_icon_active from "../../assets/images/icons/voice_chat_active.png";

export default function Sidebar() {
    let navigate = useNavigate()
    let [showPopular, setShowPopular]=useState(false)
    return (
        <div className="sidebar">
            <div onClick={() => navigate("/webapp_user_home")} className="sidebar_logo">
                <img src={logo} alt="logo" className="logo"/>
            </div>
            <div>
                <div className="d-flex fd_column justify_content_center align_items_center">
                    <div className="button_border library_btn_b">
                        <div className="button_border_back">
                            <div className="d_flex justify_content_start "
                                 onClick={() => navigate("")}>
                                <button className="contact_us_btn bc_colorful fs_14 f_500 library_btn d_flex align_items_center"><img src={write_message} alt=""/> Library</button>
                            </div>
                        </div>
                    </div>
                    <div className="d_flex fd_column justify_content_space_between sidebar_navlinks_b">
                        <div className="d_flex fd_column">
                            <NavLink
                                className={({isActive}) => isActive ? 'sidebar_navLink_active  c_white fs_14 f_700' : 'sidebar_navLink fs_14 f_600'}
                                to="/">Webchat <span>102</span></NavLink>
                            <NavLink
                                className={({isActive}) => isActive ? 'sidebar_navLink_active c_white fs_14 f_700' : 'sidebar_navLink fs_14 f_600'}
                                to="/"> Voice Chat</NavLink>
                            <NavLink
                                className={({isActive}) => isActive ? 'sidebar_navLink_active c_white fs_14 f_700' : 'sidebar_navLink fs_14 f_600'}
                                to="/">Image Chat</NavLink>
                            <NavLink
                                className={({isActive}) => isActive ? 'sidebar_navLink_active c_white fs_14 f_700' : 'sidebar_navLink fs_14 f_600'}
                                to="/">Video Chat</NavLink>
                            <NavLink
                                className={({isActive}) => isActive ? 'sidebar_navLink_active c_white fs_14 f_700' : 'sidebar_navLink fs_14 f_600'}
                                to="/">Favorite queries</NavLink>
                        </div>
                        <div>
                            <div className="d_flex fd_column align_items_center sidebar_navlinks_b2" >
                                <div className="sidebar_navlinks_b2_item d_flex justify_content_space_between align_items_center fs_12 f_600" onClick={()=>setShowPopular(!showPopular)}>
                                    MOST POPULAR QUERIES
                                    <img src={showPopular? arrow_up : arrow_down } alt=""/>
                                </div>
                            </div>
                            {
                                showPopular ?
                                    <div className="sidebar_navlinks_b2_item_b">
                                        <div className="sidebar_navlinks_b2_item d_flex justify_content_space_between align_items_center fs_14 f_500" onClick={()=>setShowPopular(!showPopular)}>
                                            <div className="circle_purple"> </div>
                                            Most used query 1
                                            <span className="c_lightGrey fs_14 f_500">8</span>
                                        </div>
                                        <div className="sidebar_navlinks_b2_item d_flex justify_content_space_between align_items_center fs_14 f_500" onClick={()=>setShowPopular(!showPopular)}>
                                            <div className="circle_green"> </div>
                                            Most used query 2
                                            <span className="c_lightGrey fs_14 f_500">45</span>
                                        </div>
                                        <div className="sidebar_navlinks_b2_item d_flex justify_content_space_between align_items_center fs_14 f_500" onClick={()=>setShowPopular(!showPopular)}>
                                            <div className="circle_blue"> </div>
                                            Most used query 3
                                            <span className="c_lightGrey fs_14 f_500">87</span>
                                        </div>
                                        <div className="sidebar_navlinks_b2_item d_flex justify_content_space_between align_items_center fs_14 f_500" onClick={()=>setShowPopular(!showPopular)}>
                                            <div className="circle_yellow"> </div>
                                            Most used query 4
                                            <span className="c_lightGrey fs_14 f_500">197</span>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}