import "./style.css";
import {useNavigate} from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

export default function Policy() {
    let navigate = useNavigate()
    return (
        <div className="main">
            <div className="bc_white">
                <Header/>
                <div className="container_big_sizes bc_white">
                    <div className="policy_cont d_flex fd_column">
                        <p className="c_purple text_center font_Inter f_600 fs_16 current_date_text">Current as of June 15, 2023</p>
                        <h2 className="c_black text_center fs_48 font_Inter f_600">Privacy Statement</h2>
                        <div className="c_black text_center margin_top_24">
                            CogiBot is committed to respecting and protecting your privacy. We have prepared this
                            privacy policy to describe our practices regarding the personal data we collect from users
                            of our website and SaaS applications.
                        </div>
                        <div className="margin_top_96">
                            <h2 className="font_Inter fs_24 f_600">Data Collection and Usage</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                We designed our software to gather data from your ERP systems through our web interface
                                and mobile application. Please note, however, that we do not record or store your ERP
                                data or any payment/card information on our systems. The data remains solely under your
                                control and is utilized only to provide the service you've engaged us for.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">Data Security</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                We have implemented rigorous security measures, including point-to-point encryption, to
                                protect your data from unauthorized access, alteration, disclosure, or destruction. We
                                regularly update our security protocols to address emerging threats.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">Access Roles </h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                Our software allows you to manage who can access your ERP data and to what extent. This
                                feature helps enhance the security of your sensitive data.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">Personal Information</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                We may collect certain personal information, such as contact details and login
                                credentials, to facilitate your use of our services. This data is used solely to improve
                                your user experience and will not be shared with third parties without your explicit
                                consent.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">Payment Information</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                We never store your payment or card data. All transactions are processed using secure,
                                PCI-compliant methods.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">Cookies</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                Our website and application may use "cookies" to enhance the user experience. You have
                                the option to accept or decline these cookies through your browser settings.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">Compliance with Laws</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                We will disclose your personal data where required to do so by law or subpoena or if we
                                believe that such action is necessary to comply with the law and the reasonable requests
                                of law enforcement or to protect the security or integrity of our service.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">Changes to This Privacy Policy</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                This Privacy Policy is effective as of June 15, 2023, and will remain in effect except
                                with respect to any changes in its provisions in the future, which will be in effect
                                immediately after being posted on this page.
                            </div>
                        </div>
                        <div className="margin_top_48">
                            <h2 className="font_Inter fs_24 f_600">How can you contact us about this policy?</h2>
                            <div className="margin_top_24 fs_18 f_400 font_Inter">
                                If you have any questions about this privacy policy, please contact us at <a
                                href="mailto:cs@cogibot.ai" target="_blank">cs@cogibot.ai</a>.
                            </div>
                        </div>
                    </div>


                </div>


            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}