import "./style.css";
import contact_sales_pic_back from "../../assets/images/contact_sales_pic.png";
import contact_sales_pic from "../../assets/images/contact_sales_pic1.png";
import help_icon from "../../assets/images/icons/help-circle_icon-grey.png";
import {useNavigate} from "react-router-dom";

export default function SignUp() {
    let navigate = useNavigate()
    let _createAccount =()=>{
        navigate("/sign_in")
    }
    return (
        <div className="contact_sales_cont d_flex align_items_center justify_content_space_between">
            <div className="container_big_sizes">
                <div className="d_flex justify_content_space_between align_items_center">
                    <div className="contact_sales_cont_b1">
                        <img src={contact_sales_pic_back} alt="pic_back" className="contact_sales_back_pic"/>
                        <img src={contact_sales_pic} alt="pic" className="contact_sales_pic1" onClick={()=>navigate("/")} style={{cursor:"pointer"}}/>
                    </div>
                    <div className="contact_sales_cont_b2 d_flex fd_column justify_content_space_between">
                        <p className="fs_80 title text_center Sign_up_title font_WorkSans">Sign up</p>
                        <div className="sign_up_form_cont d_flex fd_column">
                            <div className="border_wrap ">
                                <div className="contact_sales_form_cont_block">
                                    {/*<img src={close_icon_grey} alt="close_icon" className="close_icon"/>*/}
                                    <h2 className="fs_24  f_600  margin_top_24">Create Your Account</h2>
                                    <div className="margin_top_24 d_flex fd_column justify_content_start contact_sales_form_cont_item">
                                        <label htmlFor="" className="fs_14 c_grey f_400">Enter your email</label>
                                        <input type="email" placeholder="Email" className="fs_14"/>
                                    </div>
                                    <div className="margin_top_24 d_flex fd_column justify_content_start contact_sales_form_cont_item">
                                            <label htmlFor="" className="fs_14 c_grey f_400">Password</label>
                                            <input type="password" placeholder="Password" className="fs_14"/>
                                    </div>
                                    <div className="margin_top_24 d_flex fd_column justify_content_start contact_sales_form_cont_item">
                                        <label htmlFor="" className="fs_14 c_grey f_400">Repeat password</label>
                                        <input type="password" placeholder="Repeat password" className="fs_14"/>
                                    </div>
                                    <div className="border_wrap_send_btn margin_top_24" onClick={_createAccount}>
                                        <div className="border_wrap_send_btn_inner">
                                            <div className="d_flex justify_content_center  send_btn_b bc_colorful">
                                                <button className="send_btn fs_14 f_600">Create Your Account</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="contact_sales c_grey fs_14 text_center">Have a question?<a
                               onClick={()=>navigate("/contact")} className="">Contact Us.</a>
                            </p>
                        </div>

                        <div className="d_flex align_items_center justify_content_space_between">
                            <p className="font_Rubik c_grey fs_14 f_400">©Cogibot</p>
                            <p className="d_flex align_items_center c_grey fs_14"><img src={help_icon} alt="help_icon"
                                                                                       className="help_icon"/>Support
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}