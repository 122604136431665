import Modal from 'react-modal';
import "./style.css";
import {useState} from "react";
import close_icon_grey from "../../../../assets/images/icons/close_icon_grey.png";
import {useNavigate} from "react-router-dom";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: "524px",
        width: "100%"
    },
};

export default function ContinueResetPasswordModal(props) {
    let navigate = useNavigate()


    return (
        <div className="">
            <Modal
                isOpen={props.resetPasswordContinueModal}
                onRequestClose={props.closeResetPasswordContinueModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className=" d_flex fd_column modal_content">
                    <img src={close_icon_grey} alt="close_icon" className="close_icon"
                         onClick={props.closeResetPasswordContinueModal}/>
                    <h2 className="fs_24  f_600  margin_top_16">Reset password</h2>
                    <div className="fs_14 f_600 c_grey margin_top_16 reset_info_text">
                        We have sent a password recovery link to <span className="c_colorful">exampleofemail@test.com</span>. Please, check your email.
                    </div>
                    <div className="c_white fs_14 f_600 margin_top_16">this link still active  <span style={{color:"#F77421"}}> 1h</span></div>
                    <div className="d_flex align_items_center justify_content_space_between margin_top_24 or_line">
                        <div> </div>
                        <span className="c_grey fs_14 f_400">or</span>
                        <div> </div>
                    </div>
                    <div className="fs_14 f_400 c_white  reset_info_text margin_top_24">
                        <p>Still no code in your inbox?</p>
                        Check your spam, or: <span className="c_colorful" onClick={props.closeContinueResetPassword}>Use other email</span>.
                    </div>
                </div>
            </Modal>
        </div>
    );
}