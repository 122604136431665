import "./style.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import home_b2_pic from "../../assets/images/home_b2_pic.svg";
import home_b3_pic from "../../assets/images/home_b3_pic.svg";
import home_b4_pic from "../../assets/images/home_b4_pic.svg";
import arrow_right_white from "../../assets/images/icons/arrow_right_white.svg";
import {useNavigate} from "react-router-dom";
import {ParticlesComponent} from "./components/particles/Particles";

export default function Home() {
    let navigate = useNavigate()

    return (
        <div className="main">
            <div className="">
                <Header isLogin={false}/>
                <div className="home_b1">
                    {/*<ParticlesComponent/>*/}
                    <div className="home_b1_content d_flex fd_column align_items_center justify_content_center">
                        <h1 className="fd_96 text_center f_400 font_WorkSans">Easiest ERP
                            Interface Ever</h1>
                        <div className="fs_24 f_400 font_WorkSans text_center margin_top_32">Blazingly fast, delightful,
                            and stress
                            free. Get 6 hours back every single week.
                        </div>
                        <div className="button_border margin_top_64" style={{maxWidth: "279px", width: "100%"}}>
                            <div className="button_border_back">
                                <div className="contact_us_btn_b bc_colorful"
                                     onClick={() => navigate("/contact")}>
                                    <button className="contact_us_btn fs_20 c_white">Contact us</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="home_b1_content2">
                        <p className="f_400">10X faster</p>
                        <div className="fs_48 text_center f_400">Get information you need from ERP</div>
                    </div>
                </div>
                <div className="container_big_sizes">
                    <div className="home_cont">
                        <div className="home_b2 d_flex align_items_center justify_content_space_between">
                            <div className="home_b2_b1 d_flex fd_column">
                                <span className="fs_42 f_500 font_WorkSans">Speak, Type, or Snap:</span>
                                <p className="fs_42 f_500 font_WorkSans">Instant Data Extraction at Your Command</p>
                                <p className="fs_24 f_400 font_Inter margin_top_16">Tired of time-intensive data
                                    retrieval processes?</p>
                                <div className="info_b fs_24 f_400 margin_top_16 font_Inter">
                                    With Cogybot's 'Speak, Type, or Snap' functionality, escape the drudgery of
                                    traditional
                                    data extraction and enjoy quick, efficient access to your critical business
                                    information
                                    at your command.
                                </div>
                                <div className="button_border margin_top_32">
                                    <div className="button_border_back">
                                        <div className="d_flex justify_content_start contact_us_btn_b "
                                             onClick={() => navigate("/contact")}>
                                            <button className="contact_us_btn bc_colorful fs_20">Contact Sales</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_b2_b2">
                                <img src={home_b2_pic} alt="pic"/>
                            </div>
                        </div>
                        <div className="home_b3 d_flex align_items_center margin_top_140">
                            <div className="home_b3_b1">
                                <img src={home_b3_pic} alt="pic"/>
                            </div>
                            <div className="home_b3_b2 d_flex fd_column">
                                <span className="fs_42 f_500 font_WorkSans">AI Intuition:</span>
                                <p className="fs_42 f_500 font_WorkSans">
                                    User-Friendly, No Training Required</p>
                                <p className="fs_24 f_400 font_Inter margin_top_16 font_Inter">Tired of time-intensive
                                    data retrieval processes?</p>
                                <div className="info_b fs_24 f_400 margin_top_16 font_Inter">
                                    With Cogybot's 'Speak, Type, or Snap' functionality, escape the drudgery of
                                    traditional
                                    data extraction and enjoy quick, efficient access to your critical business
                                    information
                                    at your command.
                                </div>
                                <div className="button_border margin_top_32">
                                    <div className="button_border_back">
                                        <div className="d_flex justify_content_start contact_us_btn_b "
                                             onClick={() => navigate("/contact")}>
                                            <button className="contact_us_btn bc_colorful fs_20">Contact Sales</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="home_b4 d_flex align_items_center justify_content_space_between margin_top_140">
                            <div className="home_b4_b1 d_flex fd_column">
                                <span className="fs_42 f_500 font_WorkSans">Always Updated:</span>
                                <p className="fs_42 f_500 font_WorkSans">Real-Time Data with AI Precision</p>
                                <p className="fs_24 f_400 font_Inter margin_top_16">Frustrated with outdated information
                                    and sluggish databases?</p>
                                <div className="info_b fs_24 f_400 margin_top_16 font_Inter">
                                    With Cogybot, seamlessly connect to your SAP database and empower your
                                    decision-making with instant access to real-time data, anytime, anywhere
                                </div>
                                <div className="button_border margin_top_32">
                                    <div className="button_border_back">
                                        <div className="d_flex justify_content_start contact_us_btn_b "
                                             onClick={() => navigate("/contact")}>
                                            <button className="contact_us_btn bc_colorful fs_20">Contact Sales</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="home_b4_b2">
                                <img src={home_b4_pic} alt="pic"/>
                            </div>
                        </div>
                        <div className="button_border margin_top_140 margin_bottom_150 contact_sales_big_btn" >
                            <div className="button_border_back" style={{borderRadius:"24px"}}>
                                <div className="contact_sales_b  ">
                                    <div
                                        className="contact_sales_btn_b d_flex justify_content_space_between align_items_center"
                                        onClick={() => navigate("/contact")} style={{margin:"unset"}}>
                                        <p className="font_WorkSans fs_80">Contact Sales</p>
                                        <img src={arrow_right_white} alt="arrow_pic" className="arrow_pic_white"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div>
                <Footer/>
            </div>
        </div>
    )
}