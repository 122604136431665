import "./style.css";
import add_photo_pic from "../../../../../assets/images/icons/add_photo_icon.png";


export default function CompanyProfile(){
    return (
        <div className="admin_profile_cont">
            <div className="user_info_cont company_profile_cont d_flex justify_content_space_between align_items_start">
                <div className="company_profile_cont_b1">
                    <p className="fs_20 f_600">Company profile</p>
                    <div className="user_info_cont_b1_inputs_b  margin_top_24">
                        <div className="d_flex fd_column justify_content_start user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">Company Name</label>
                            {/*<input type="text" placeholder=""/>*/}
                            <span className="fs_14 f_400 c_white">Cogibot</span>
                        </div>
                        <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">Contacts</label>
                            {/*<input type="text" placeholder="Codibot"/>*/}
                            <span className="fs_14 f_400 c_white">MBS Residence number 28C, Depok Sleman, Yogyakarta, Indonesia</span>
                        </div>
                        <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">Phone Number</label>
                            {/*<input type="email" placeholder="Email"/>*/}
                            <span className="fs_14 f_400 c_white">+62 812 231 731 / +62 811 984 312</span>
                        </div>
                        <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">Website</label>
                            {/*<input type="text" placeholder="Job Description"/>*/}
                            <div className="inputs_span_colorful d_flex ">
                                <span>dribbble.com/brettdarke</span>
                            </div>
                        </div>
                        <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">Website</label>
                            {/*<input type="text" placeholder="Job Description"/>*/}
                            <span>Type 1</span>
                            <div className="inputs_span_colorful d_flex">
                                <span>Change type</span>
                            </div>
                        </div>
                        <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">License last day</label>
                            {/*<input type="email" placeholder="Email"/>*/}
                            <span className="fs_14 f_400 c_white">June 2024</span>
                        </div>
                        <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">Admin Account</label>
                            {/*<input type="email" placeholder="Email"/>*/}
                            <span className="fs_14 f_400 c_white">Name Surname</span>
                        </div>
                        <div className="d_flex fd_column justify_content_start margin_top_24 user_info_cont_b1_input_b company_profile_cont_b1_input_b">
                            <label htmlFor="" className="fs_14 f_400 c_lightGrey">User count</label>
                            {/*<input type="email" placeholder="Email"/>*/}
                            <span className="fs_14 f_400 c_white">234</span>
                        </div>
                    </div>
                </div>
                <div className="user_info_cont_b2 d_flex fd_column justify_content_center">
                    <img src={add_photo_pic} alt="admin_pic" className="admin_pic"/>
                    <p className="margin_top_16 fs_14 f_600 text_center">Company logo</p>
                    <div className="upload_delete_pic_btns_b d_flex">
                        <button className="fs_14 f_400 font_Roboto">Upload</button>
                        /
                        <button className="fs_14 f_400 font_Roboto">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}