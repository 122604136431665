import "./style.css";
import edit_icon from "../../../../../assets/images/icons/edit_icon.png";
import ok_icon from "../../../../../assets/images/icons/ok_icon_grey.png";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import UpgradeModal from "../upgrade_modal/UpgradeModal";

export default function Subscription() {
    let navigate = useNavigate()
    let [token, setToken] = useState(localStorage.getItem("jwtToken") || "");
    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false);

    let closeUpgradeModal = () => {
        setUpgradeModalIsOpen(false)
        console.log("close")
    }

    function openUpgradeModal() {
     setUpgradeModalIsOpen(true)
        console.log("true")
        // setChangePasswordModalIsOpen(true)
    }
    return (
        <>
        <div className="admin_profile_cont">
            <div className="subcription_cont">
                <div className="subcription_cont_item d_flex justify_content_space_between align_items_start">
                    <div className="d_flex fd_column">
                        <div className="d_flex  subcription_cont_item_header">
                            <div className="d_flex fd_column justify_content_start subcription_cont_item_header_p1">
                                <h2 className="fs_20 f_600">Plan Enterprise</h2>
                                <div>Seat count<span className="text_colorful">5 User</span></div>
                            </div>
                        </div>
                        <div className="d_flex align_items_center subcription_cont_item_b1">
                            <div className="d_flex align_items_center subcription_cont_item_b1_item">
                                <span className="c_white fs_16 f_600 subcription_cont_item_b1_item_price">$5000</span>
                                <div className="c_grey fs_12 d_flex fd_column">
                                    <span>per user</span>
                                    <span>per 6 months</span>
                                </div>
                            </div>
                            <div className="d_flex align_items_center subcription_cont_item_b1_item">
                                <span className="c_white fs_16 f_600">2</span>
                                <div className="c_grey fs_12 d_flex fd_column">
                                    <span>seats</span>
                                    <span>available</span>
                                </div>
                            </div>
                            <div className="d_flex align_items_center subcription_cont_item_b1_item">
                                <span className="c_white fs_16 f_600">3</span>
                                <div className="c_grey fs_12 d_flex fd_column">
                                    <span>users</span>
                                    <span>active</span>
                                </div>
                            </div>
                            <div className="edit_users d_flex align_items_center">
                                <img src={edit_icon} alt="edit_icon"/>
                                <p className="text_colorful">Edit users</p>
                            </div>
                        </div>
                        <div className="included_plan_div">
                            <p className="fs_12 f_600">Included in plan:</p>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 1
                            </div>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 2
                            </div>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 3
                            </div>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 4
                            </div>
                        </div>
                    </div>
                    <div className="d_flex align_items_center cancel_save_btn">
                        <div className="button_border" style={{marginLeft: "16px"}}>
                            <div className="button_border_back">
                                <div className="d_flex justify_content_start cancel_btn_b">
                                    <button className=" fs_14 f_600 cancel_btn">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subcription_cont_item d_flex justify_content_space_between align_items_start  margin_top_24">
                    <div className="d_flex fd_column">
                        <div className="d_flex  subcription_cont_item_header">
                            <div className="d_flex fd_column justify_content_start subcription_cont_item_header_p1">
                                <h2 className="fs_20 f_600">Plan Enterprise</h2>
                                <div>Seat count<span className="text_colorful">5 User</span></div>
                            </div>
                        </div>
                        <div className="d_flex align_items_center subcription_cont_item_b1">
                            <div className="d_flex align_items_center subcription_cont_item_b1_item">
                                <span className="c_white fs_16 f_600 subcription_cont_item_b1_item_price">$5000</span>
                                <div className="c_grey fs_12 d_flex fd_column">
                                    <span>per user</span>
                                    <span>per 6 months</span>
                                </div>
                            </div>
                            <div className="d_flex align_items_center subcription_cont_item_b1_item">
                                <span className="c_white fs_16 f_600">2</span>
                                <div className="c_grey fs_12 d_flex fd_column">
                                    <span>seats</span>
                                    <span>available</span>
                                </div>
                            </div>
                        </div>
                        <div className="included_plan_div">
                            <p className="fs_12 f_600">Included in plan:</p>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 1
                            </div>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 2
                            </div>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 3
                            </div>
                            <div className="d_flex align_items_center c_grey">
                                <img src={ok_icon} alt=""/>
                                Feature 4
                            </div>
                        </div>
                    </div>
                    <div className="d_flex align_items_center cancel_save_btn">
                        <div className="button_border">
                            <div className="button_border_back">
                                <div className="d_flex justify_content_start upgrate_plan_b" onClick={openUpgradeModal}>
                                    <button className="bc_colorful fs_14 f_600">Upgrade to this plan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
            <UpgradeModal upgradeModalIsOpen={upgradeModalIsOpen} setUpgradeModalIsOpen={setUpgradeModalIsOpen}
                          closeUpgradeModal={closeUpgradeModal} openUpgradeModal={openUpgradeModal}
            />
        </>
    )
}