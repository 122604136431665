import "./style.css";
import Header from "../../../components/header/header";
import Sidebar from "../../../components/sidebar/Sidebar";
import arrow_right_white from "../../../assets/images/icons/arrow_right_white.svg";

export default function WepAppUserHome() {

    return (
        <div className="webapp_user_back">
            <Sidebar/>
            <Header isLogin={true}/>
            <div className="WepAppUserHome_cont">
                <div className="button_border" style={{borderRadius: "16px", width: "100%"}}>
                    <div className="button_border_back" style={{borderRadius: "16px"}}>
                        <div className="webapp_user_header">
                            <div
                                className="webapp_user_header_b d_flex justify_content_space_between align_items_center">
                                <div className="webapp_user_header_b1 d_flex align_items_center">
                                    <input type="text" placeholder="E.g. “Give me the all names of sales people in company"/>
                                    <button className="search_btn f_600 fs_14">Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}