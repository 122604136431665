import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import Home from "./pages/Home/Home";
import DataPrivacy from "./pages/Data_Privacy/DataPrivacy";
import ContactSales from "./pages/Contact_Sales/ContactSales";
import Policy from "./pages/Policy/Policy";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import WepAppUserHome from "./pages/WebApp-User/WebApp_User_Home/WepAppUserHome";
import {useState} from "react";
import AdminProfile from "./pages/WebApp-Admin/AdminProfile/AdminProfile";

function App() {
  let [token, setToken] = useState(localStorage.getItem("token") || "");
  return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/"  element={<Home />}/>
              <Route path="/privacy"        element={<DataPrivacy />}/>
              <Route path="/contact"  element={<ContactSales />}/>
              <Route path="/policy"  element={<Policy />}/>
              <Route path="/sign_up"  element={<SignUp />}/>
              <Route path="/sign_in"  element={<SignIn />}/>


              <Route element={<PrivateRoute/>} >
              <Route path="/webapp_user_home" token={true} element={<WepAppUserHome />}/>
                <Route path="/admin_profile" token={true} element={<AdminProfile />}/>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
